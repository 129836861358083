import { lazy, Suspense } from 'react';
import { Outlet, Navigate, useRoutes } from 'react-router-dom';

import CompactLayout from 'src/layouts/compact';
import { SplashScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

const TrackingPage = lazy(() => import('src/pages/tracking'));

export default function Router() {
  return useRoutes([
    {
      element: (
        <CompactLayout>
          <Suspense fallback={<SplashScreen />}>
            <Outlet />
          </Suspense>
        </CompactLayout>
      ),
      children: [
        { path: '/', element: <TrackingPage />, index: true },
        { path: 'track/:id', element: <TrackingPage /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
