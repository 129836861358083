import { memo } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
// import { useTheme } from '@mui/material/styles';

import Image from 'src/components/image';

// ----------------------------------------------------------------------

function Logo({ single = false, sx }) {
  // const theme = useTheme();

  // const PRIMARY_MAIN = theme.palette.primary.main;

  const singleLogo = <Image src="/assets/logo/quantum-single.png" />;

  const fullLogo = <Image src="/assets/logo/quantum-full.png" />;

  return (
    <Box
      sx={{
        width: single ? 64 : 150,
        lineHeight: 0,
        cursor: 'pointer',
        display: 'inline-flex',
        ...sx,
      }}
    >
      {single ? singleLogo : fullLogo}
    </Box>
  );
}

Logo.propTypes = {
  single: PropTypes.bool,
  sx: PropTypes.object,
};

export default memo(Logo);
