import 'src/global.css';

// ----------------------------------------------------------------------

import ThemeProvider from 'src/theme';
import Router from 'src/routes/sections';
import ProgressBar from 'src/components/progress-bar';

// ----------------------------------------------------------------------

export default function App() {
  return (
    <ThemeProvider>
      <ProgressBar />
      <Router />
    </ThemeProvider>
  );
}
